import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestion-calidad',
  templateUrl: './gestion-calidad.component.html',
  styleUrls: ['./gestion-calidad.component.scss']
})
export class GestionCalidadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
