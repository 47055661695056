import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  constructor() {}

  clients = [
    {
      name: 'Raghsa',
      picture: 'raghsa.png'
    },
    {
      name: 'Irsa',
      picture: 'irsa.png'
    },
    // {
    //   name: 'Grupo Florencio Iglesias',
    //   picture: ''
    // },
    {
      name: 'Banco Santander',
      picture: 'santanderrio.png'
    },
    {
      name: 'Banco Galicia',
      picture: 'galicia.png'
    },
    {
      name: 'Banco Macro',
      picture: 'macro.png'
    },
    {
      name: 'Consultatio',
      picture: 'consultatio.png'
    },
    {
      name: 'Banco Patagonia',
      picture: 'banco-patagonia.png'
    },
    {
      name: 'Swiss Medical',
      picture: 'swissmedical.png'
    },
    {
      name: 'YPF',
      picture: 'ypf.png'
    },
    {
      name: 'Telecom',
      picture: 'telecom.png'
    },
    // {
    //   name: 'Grupo Gonzalez',
    //   picture: ''
    // },
    {
      name: 'Ford',
      picture: 'ford.png'
    },
    {
      name: 'Nobleza Picardo',
      picture: 'nobleza-picardo.png'
    },
    {
      name: 'Massalin Particulares',
      picture: 'massali.png'
    },
    {
      name: 'La Prensa',
      picture: 'laprensa.png'
    },
    {
      name: 'NH',
      picture: 'nh.png'
    },
    {
      name: 'Hotel Alvear',
      picture: 'alvear.png'
    },
    {
      name: 'Hotel Sheraton',
      picture: 'sheraton.png'
    },
    {
      name: 'Hotel Hilton',
      picture: 'hilton.png'
    },
    {
      name: 'Hotel Hyatt',
      picture: 'hyatt.png'
    },
    {
      name: 'Hotel Ibis',
      picture: 'ibis.png'
    },
    {
      name: 'Hotel Novotel',
      picture: 'novotel.png'
    },
    {
      name: 'La Nación',
      picture: 'la-nacion.png'
    },
    {
      name: 'Grupo Clarín',
      picture: 'grupo-clarin.png'
    }
  ];

  ngOnInit() {}
}
