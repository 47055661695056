import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ActivationStart } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  alternative = true;
  bgColor = 'transparent';

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof ActivationStart) {
        this.alternative = event.snapshot.data.fontColor ? true : false;
        this.bgColor = event.snapshot.data.bgColor || 'transparent';
      }
    });
  }
  ngOnInit() {}
}
