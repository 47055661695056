import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { WorksComponent } from './works/works.component';
import { ContactComponent } from './contact/contact.component';
import { ClientsComponent } from './clients/clients.component';
import { RseComponent } from './rse/rse.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ServicesComponent } from './services/services.component';
import { GestionCalidadComponent } from './gestion-calidad/gestion-calidad.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      fontColor: false,
      animation: 'Home'
    }
  },
  {
    path: 'nosotros',
    component: AboutUsComponent,
    data: {
      fontColor: false,
      animation: 'About'
    }
  },
  {
    path: 'obras',
    component: WorksComponent,
    data: {
      fontColor: true,
      animation: 'Works'
    }
  },
  {
    path: 'clientes',
    component: ClientsComponent,
    data: {
      fontColor: true,
      animation: 'Clients'
    }
  },
  {
    path: 'servicios',
    component: ServicesComponent,
    data: {
      fontColor: false,
      bgColor: '#312783',
      animation: 'Services'
    }
  },
  {
    path: 'rse',
    component: RseComponent,
    data: {
      fontColor: false,
      bgColor: '#312783',
      animation: 'RSE'
    }
  },
  {
    path: 'gestion-calidad',
    component: GestionCalidadComponent,
    data: {
      fontColor: false,
      bgColor: '#312783',
      animation: 'RSE'
    }
  },
  {
    path: 'contacto',
    component: ContactComponent,
    data: {
      fontColor: true,
      animation: 'Contact'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
