import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  showSuccessMessage = false;

  constructor(private http: HttpClient) {
    this.contactForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(50)
      ]),
      subject: new FormControl('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      message: new FormControl('', [
        Validators.required,
        Validators.maxLength(50)
      ])
    });
  }

  ngOnInit() {}

  sendContact() {
    if (this.contactForm.invalid) {
      return;
    }

    const formValue = this.contactForm.value;

    const formData = new FormData();
    formData.append('name', formValue.name);
    formData.append('email', formValue.email);
    formData.append('subject', formValue.subject);
    formData.append('message', formValue.message);

    this.http
      .post('contact.php', formData)
      .toPromise()
      .then(result => {
        console.log(result);
        this.showSuccessMessage = true;
      })
      .catch(error => {
        console.log(error);
        this.showSuccessMessage = true;
      });
  }
}
