import {
  transition,
  trigger,
  query,
  style,
  animate,
  group
} from '@angular/animations';
export const slideInAnimation = trigger('routeAnimations', [
  transition('Home => *', [config(), group([moveLeft()])]),
  transition('* => Home', [config(), group([moveRight()])]),

  transition('About => Works', [config(), group([moveLeft()])]),
  transition('About => Clients', [config(), group([moveLeft()])]),
  transition('About => Services', [config(), group([moveLeft()])]),
  transition('About => RSE', [config(), group([moveLeft()])]),

  transition('Works => Clients', [config(), group([moveLeft()])]),
  transition('Works => Services', [config(), group([moveLeft()])]),
  transition('Works => RSE', [config(), group([moveLeft()])]),
  transition('Works => About', [config(), group([moveRight()])]),

  transition('Clients => Services', [config(), group([moveLeft()])]),
  transition('Clients => RSE', [config(), group([moveLeft()])]),
  transition('Clients => Works', [config(), group([moveRight()])]),
  transition('Clients => About', [config(), group([moveRight()])]),

  transition('Services => RSE', [config(), group([moveLeft()])]),
  transition('Services => Clients', [config(), group([moveRight()])]),
  transition('Services => Works', [config(), group([moveRight()])]),
  transition('Services => About', [config(), group([moveRight()])]),

  transition('RSE => Services', [config(), group([moveRight()])]),
  transition('RSE => Clients', [config(), group([moveRight()])]),
  transition('RSE => Works', [config(), group([moveRight()])]),
  transition('RSE => About', [config(), group([moveRight()])]),

  transition('Contact => *', [config(), group([moveRight()])]),
  transition('* => Contact', [config(), group([moveLeft()])])
]);

function config() {
  return query(':enter, :leave', style({ position: 'fixed', width: '100%' }), {
    optional: true
  });
}

function moveRight() {
  return (
    query(
      ':enter',
      [
        style({ transform: 'translateX(-50%)', opacity: 0 }),
        animate(
          '0.25s ease-in-out',
          style({ transform: 'translateX(0%)', opacity: 1 })
        )
      ],
      { optional: true }
    ),
    query(
      ':leave',
      [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate(
          '0.25s ease-in-out',
          style({ transform: 'translateX(50%)', opacity: 0 })
        )
      ],
      { optional: true }
    )
  );
}

function moveLeft() {
  return (
    query(
      ':enter',
      [
        style({ transform: 'translateX(50%)', opacity: 0 }),
        animate(
          '0.25s ease-in-out',
          style({ transform: 'translateX(0%)', opacity: 1 })
        )
      ],
      { optional: true }
    ),
    query(
      ':leave',
      [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate(
          '0.25s ease-in-out',
          style({ transform: 'translateX(-50%)', opacity: 0 })
        )
      ],
      { optional: true }
    )
  );
}
