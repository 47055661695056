import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.scss']
})
export class WorksComponent implements OnInit {
  selected = null;
  works = [];

  constructor(private http: HttpClient) {
    this.http
      .get('assets/data/works.json')
      .toPromise()
      .then((data: Array<any>) => {
        this.works = data;
        this.selected = this.works[0];
      });
  }

  ngOnInit() {}
}
