import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss']
})
export class ImageLoaderComponent implements OnInit {
  @Input() picture: string;
  @Input() folder: string;
  picturePath = '';
  loading = true;

  constructor() {}

  ngOnInit() {
    if (this.picture) {
      this.picturePath = encodeURI(
        './assets/' + this.folder + '/' + this.picture
      );
    }
    this.loading = true;
  }

  loaded() {
    this.loading = false;
  }
}
